import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

export default ({msg = "Page not found"}) =>
<Switch>
  <Redirect from='/blog/' to='/blog' />
  <Redirect from='/:lang([a-z]{2}/blog/' to='/blog' />
  <Redirect to='/' />
</Switch>
  // <section className="hero is-info">
  //   <div className="hero-body">
  //     <div className="not-found">
  //       <h1 className="title">{msg}</h1>
  //       <br />
  //       <Link to="/">Go Back to the homepage</Link>
  //     </div>
  //   </div>
  // </section>
