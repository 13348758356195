import React from 'react'
import { withRouter } from 'react-router'
import Loadable from 'react-loadable'
import { Link, NavLink } from 'react-router-dom'
import { linkResolver } from '../components/prismic'
import * as PrismicHelpers from '@prismicio/helpers'
import dayjs from 'dayjs'
import slugify from 'slugify'

export const SC_DEFAULT_LANG = 'en'
export const SC_DEFAULT_LANG_COUNTRIES = {
  en: 'en-us',
  fr: 'fr-fr',
  es: 'es-es',
  ja: 'ja-jp'
}
export const SC_SIGN_UP_URL = '//app.soundcharts.com/signup'
export const SC_SIGN_UP_URL_FORMAT = SC_SIGN_UP_URL + '?email='

export const getLangWithCountry = (lang) => {
  return lang ? SC_DEFAULT_LANG_COUNTRIES[lang] : undefined
}

const SC_API_TOKEN = 'SOUNDCHARTS_F3DEBBF5'
const SC_API_CHART_ENDPOINT = 'https://proxy.api.soundcharts.com/api/v1'
const SC_API_CHART_SONG_PATH = '/chart/song/custom/airplay_weekly'
const SC_API_CHART_ARTIST_PATH = '/chart/artist/custom/airplay_weekly'

let _chartCountries = []
export const SC_COUNTRY_GLOBAL = { name: 'Global', countryCode: 'ALL', slug: 'global' }

const getSCAPIAvailableRankingsUrl = (type, countrySlug) => {
  const country = getChartCountry(countrySlug)

  let val = SC_API_CHART_ENDPOINT

  val = val + (type === 'artist' ? SC_API_CHART_ARTIST_PATH : SC_API_CHART_SONG_PATH) + '/' + country.countryCode.toUpperCase() + '%7CALL'

  val = val + '/weekly/available-rankings?token=' + SC_API_TOKEN

  return val
}

export const getSCChartSongAvailableRankings = async (countrySlug) => {
  const result = await (await fetch(getSCAPIAvailableRankingsUrl('song', countrySlug))).json()
  return result.items
}

export const getSCChartArtistAvailableRankings = async (countrySlug) => {
  const result = await (await fetch(getSCAPIAvailableRankingsUrl('artist', countrySlug))).json()
  return result.items
}

const getSCApiChartUrl = (type, countrySlug, date) => {
  const country = getChartCountry(countrySlug)

  let val = SC_API_CHART_ENDPOINT

  if (type === 'song') {
    val = val + SC_API_CHART_SONG_PATH
  } else if (type === 'artist') {
    val = val + SC_API_CHART_ARTIST_PATH
  }

  val = val + '/' + country.countryCode.toUpperCase() + '%7CALL'

  if (date) {
    val = val + '/weekly'
  }

  val = val + '/ranking'

  if (date) {
    val = val + '/' + dayjs(date).hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DDTHH:mm:ss+00:00')
  }

  val = val + '?token=' + SC_API_TOKEN

  return val
}

export const getSCChartSong = async (countrySlug, date) => {
  return await (await fetch(getSCApiChartUrl('song', countrySlug, date))).json()
}

export const getSCChartArtist = async (countrySlug, date) => {
  return await (await fetch(getSCApiChartUrl('artist', countrySlug, date))).json()
}

export const getChartCountries = async () => {
  const url = SC_API_CHART_ENDPOINT + '/airplay/countries?token=' + SC_API_TOKEN

  if (_chartCountries.length <= 0) {
    const result = await (await fetch(url)).json()
    _chartCountries = result.items.map(item => {
      item.slug = slugify(item.name, { lower: true, remove: /[*+~.,()'"!?:@]/g })
      return item
    })

    _chartCountries.splice(0, 0, SC_COUNTRY_GLOBAL)
  }

  return _chartCountries
}

export const getChartCountry = slug => {
  return _chartCountries.find(_ => _.slug === slug)
}

const getChartPageUrl = (type, countrySlug, date, lang) => {
  const country = getChartCountry(countrySlug)

  let val = '/charts/'

  if (lang && lang !== 'en') {
    val = '/' + lang + val
  }

  if (type === 'song') {
    val = val + 'songs'
  } else if (type === 'artist') {
    val = val + 'artists'
  }

  val = val + '-airplay-' + (country ? country.slug : 'global')

  if (date) {
    val = val + '/' + dayjs(date).hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DD')
  }

  return val
}

export const getChartSongPageUrl = (countrySlug, date, lang) => {
  return getChartPageUrl('song', countrySlug, date, lang)
}

export const getChartArtistPageUrl = (countrySlug, date, lang) => {
  return getChartPageUrl('artist', countrySlug, date, lang)
}

export const trackPage = () => {
  window.gtag && window.gtag('config', window.GA_MEASUREMENT_ID, { page_path: window.location.pathname })
  // window.analytics && window.analytics.page()
}

export const trackUserRequestedTrial = (email) => {
  const args = { origin: window.location.pathname }
  if (email) {
    args.email = email
  }

  // window.analytics && window.analytics.track("User Requested Trial", args);
  window.gtag('event', 'User Requested Trial', args)
}

function loading (props) {
  if (props.error) {
    console.error(props.error)
    return <div>Error! {props.error.message}<button onClick={props.retry}>Retry</button></div>
  } else if (props.timedOut) {
    return <div>Taking a long time... <button onClick={props.retry}>Retry</button></div>
  } else if (props.pastDelay) {
    return ''
  } else {
    return null
  }
}

export const asyncImport = importFn => Loadable({
  loader: () => importFn,
  delay: 200,
  timeout: 10000,
  loading
})

export const DataLink = ({ prismicLink, children, ...rest }) => {
  if (prismicLink.link_type === 'Document') {
    const Component = rest.activeClassName ? NavLink : Link

    return <Component {...rest} to={PrismicHelpers.asLink(prismicLink, linkResolver)}>{children}</Component>
  }

  const props = { ...rest }
  delete props.exact
  delete props.activeClassName

  return (
    <a
href={PrismicHelpers.asLink(prismicLink, linkResolver)}
  target={prismicLink.target || ''}
  rel={prismicLink.target ? 'noopener' : ''} {...props}
>{children}
</a>
  )
}

export const DataImage = ({ prismicImage, ...rest }) => {
  if (!prismicImage || !prismicImage.url) return null

  return (
    <img
      {...rest}
      src={prismicImage.url}
      loading='lazy'
      alt={prismicImage.alt}
      width={prismicImage.dimensions.width}
      height={prismicImage.dimensions.height}
    />
  )
}

class scrollToTop extends React.Component {
  componentDidUpdate (prevProps) {
    if (!prevProps.location || this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      })
    }
  }

  render () {
    return null
  }
}

export const ScrollToTop = withRouter(scrollToTop)

export const socials = {
  twitter: (link = '', message = '') =>
    `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
      message
    )}&url=${encodeURIComponent(link)}`,
  facebook: (link = '') =>
    `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`,
  google: (link = '') =>
    `https://plus.google.com/share?url=${encodeURIComponent(link)}`,
  tumblr: (link = '') =>
    `http://tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(
      link
    )}`,
  reddit: (link = '') =>
    `https://reddit.com/submit/?url=${encodeURIComponent(link)}`,
  whatsapp: (link = '', message = '') =>
    `whatsapp://send?text=${encodeURIComponent(message)}%20${encodeURIComponent(
      link
    )}`,
  telegram: (link = '', message = '') =>
    `https://telegram.me/share/url?text=${encodeURIComponent(
      message
    )}&url=${encodeURIComponent(link)}`,
  vk: (link = '', message = '') =>
    `http://vk.com/share.php?title=${encodeURIComponent(
      message
    )}&url=${encodeURIComponent(link)}`,
  hacker: (link = '', message = '') =>
    `https://news.ycombinator.com/submitlink?u=${encodeURIComponent(
      link
    )}&t=${encodeURIComponent(message)}`,
  xing: (link = '', message = '') =>
    `https://www.xing.com/app/user?op=share;url=${encodeURIComponent(
      link
    )};title=${encodeURIComponent(message)}.`,
  mail: (link = '', subject, body) =>
    `mailto:?subject=${encodeURIComponent(
      subject || ''
    )}&body=${encodeURIComponent((body && `${body}\n\n${link}`) || link)}`,
  pinterest: (link = '', message = '') =>
    `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
      link
    )}&media=${encodeURIComponent(link)}&description=${encodeURIComponent(
      message
    )}`,
  linkedin: (link = '', message = '') =>
    `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      link
    )}&title=${encodeURIComponent(message)}&summary=${encodeURIComponent(
      message
    )}&source=${encodeURIComponent(link)}`
}

export const ReadingProgress = ({ target, className }) => {
  const [readingProgress, setReadingProgress] = React.useState(0)
  const scrollListener = () => {
    if (!target.current) {
      return
    }

    const element = target.current
    const scrollElement = document.documentElement
    const endPosition = element.clientHeight - scrollElement.clientHeight

    const currentScroll = scrollElement.scrollTop
    const targetTopPosition = currentScroll - element.offsetTop

    const progress = Math.min(1, Math.max(0, targetTopPosition / endPosition))

    setReadingProgress(progress * 100)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  })

  return <div className={'reading-progress-bar ' + className} style={{ width: `${readingProgress}%` }} />
}

// /// /////
// export class HubspotForm extends React.Component {
//   constructor (props) {
//     super(props)
//     this.elFormId = 'hbspt-' + Date.now()
//   }

//   componentDidMount () {
//     if (window.hbspt) {
//       window.hbspt.forms.create({
//         portalId: this.props.portalId,
//         formId: this.props.formId,
//         target: '#' + this.elFormId
//       })
//     }
//   }

//   render () {
//     return <div id={this.elFormId} />
//   }
// }
