import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"

import { asyncImport } from './helpers';

import { Preview } from './components/prismic'

const Layout = asyncImport(import("./components/layout"))
const Home = asyncImport(import("./pages/home"))
const Pricing = asyncImport(import("./pages/pricing"))
const PricingExplained = asyncImport(import("./pages/pricingexplained"))
const Datasources = asyncImport(import("./pages/datasources"))
const Changelogs = asyncImport(import("./pages/changelogs"))

const BlogHome = asyncImport(import("./pages/bloghome"))
const BlogCategory = asyncImport(import("./pages/blogcategory"))
const BlogPost = asyncImport(import("./pages/blogpost"))

const Content = asyncImport(import("./pages/content"))

// const Charts = asyncImport(import("./pages/charts"))

const ChartSong = asyncImport(import("./pages/chartsong"))
const ChartArtist = asyncImport(import("./pages/chartartist"))

const Story = asyncImport(import("./pages/story"))

const NotFound = asyncImport(import("./pages/notfound"))

/* Use components to define routes */
export default ({layout}) =>
  <Router>
    <Switch>

      <Route exact path="/preview" render={routeProps => <Preview {...routeProps} />} />

      <Layout layout={layout} path="/" exact component={Home} />
      <Layout layout={layout} path="/datasources" exact component={Datasources} />
      <Layout layout={layout} path="/pricing" exact component={Pricing} />
      
      <Layout layout={layout} path="/changelogs" exact component={Changelogs} />

      <Layout layout={layout} path="/blog" exact component={BlogHome} />
      <Layout layout={layout} path="/blog/category/:uid" exact component={BlogCategory} />
      <Layout layout={layout} path="/blog/:uid" exact navbarCss="is-blogpost" component={BlogPost} />

      <Layout layout={layout} path="/privacy" exact minimalist component={Content} />
      <Layout layout={layout} path="/terms" exact minimalist component={Content} />

      <Redirect from='/pricingexplained' to='/pricing/explained'/>
      {/* <Layout layout={layout} path="/pricingexplained" exact component={PricingExplained} /> */}
      <Layout layout={layout} path="/pricing/:uid" exact component={PricingExplained} />

      {/* <Layout layout={layout} path="/charts/:uid" exact component={Charts} /> */}

      <Layout layout={layout} path="/charts/songs-airplay-:country/:date?" exact component={ChartSong} />
      <Layout layout={layout} path="/charts/artists-airplay-:country/:date?" exact component={ChartArtist} />

      <Layout layout={layout} path="/customer-stories/:uid" exact component={Story} />

      <Layout layout={layout} path="/:lang([a-z]{2})" exact component={Home} />
      <Layout layout={layout} path="/:lang([a-z]{2})/datasources" exact component={Datasources} />

      {/* <Layout layout={layout} path="/:lang([a-z]{2})/pricing" exact component={Pricing} /> */}
      
      {/* <Layout layout={layout} path="/:lang([a-z]{2})/changelogs" exact component={Changelogs} /> */}

      {/* <Layout layout={layout} path="/:lang([a-z]{2})/blog" exact component={BlogHome} />
      <Layout layout={layout} path="/:lang([a-z]{2})/blog/category/:uid" exact component={BlogCategory} /> */}
      <Layout layout={layout} path="/:lang([a-z]{2})/blog/:uid" exact component={BlogPost} />

      <Layout layout={layout} path="/:lang([a-z]{2})/privacy" exact minimalist component={Content} />
      <Layout layout={layout} path="/:lang([a-z]{2})/terms" exact minimalist component={Content} />

      <Layout layout={layout} path="/:lang([a-z]{2})/pricing/:uid" exact component={PricingExplained} />
{/* 
      <Layout layout={layout} path="/:lang([a-z]{2})/charts/:uid" exact component={Charts} /> */}

      <Layout layout={layout} path="/:lang([a-z]{2})/charts/songs-airplay-:country/:date?" exact component={ChartSong} />
      <Layout layout={layout} path="/:lang([a-z]{2})/charts/artists-airplay-:country/:date?" exact component={ChartArtist} />

      <Layout layout={layout} path="/:lang([a-z]{2})/customer-stories/:uid" exact component={Story} />

      <Layout layout={layout} path="/:lang([a-z]{2})/:uid" exact component={Content} />

      <Layout layout={layout} path="/:uid" exact component={Content} />

      <Layout layout={layout} component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  </Router>
