import PrismicConfig from './prismic-configuration.json'
import React from 'react'
import { Link } from "react-router-dom"
import NotFound from '../../pages/notfound'
import * as Prismic from '@prismicio/client'
import { Elements } from 'prismic-reactjs'
import slugify from 'slugify'

const _options = Object.assign({}, PrismicConfig.accessToken ? {accessToken: PrismicConfig.accessToken} : {})
export const Client = Prismic.createClient(PrismicConfig.endpoint, _options)

export const linkResolver = doc => {
  if (doc.type === 'homepage') {
    return '/';
  }
  if (doc.type === 'datasources_page') {
    return '/datasources';
  }
  if (doc.type === 'pricing_page') {
    return '/pricing';
  }
  // if (doc.type === 'pricing_explained_page') {
  //   return '/pricingexplained';
  // }
  if (doc.type === 'changelogs_page') {
    return '/changelogs';
  }
  if (doc.type === 'blog_homepage') {
    return '/blog';
  }
  if (doc.type === 'blog_category') {
    return '/blog/category/' + doc.uid;
  }
  if (doc.type === 'blog_post') {
    return '/blog/' + doc.uid;
  }
  if (doc.type === 'pricing_explained_page') {
    return '/pricing/' + doc.uid;
  }
  if (doc.type === 'charts_page') {
    return '/charts/' + doc.uid;
  }
  if (doc.type === 'chart_song_page') {
    // ?? country and date ...
    return '/charts/songs-airplay-global';
  }
  if (doc.type === 'chart_artist_page') {
    // ?? country and date ...
    return '/charts/artists-airplay-global';
  }
  if (doc.type === 'story_page') {
    return '/customer-stories/' + doc.uid;
  }
  if (doc.type === 'content_page') {
    return '/' + doc.uid;
  }
  return '/';
}

export const Preview = class extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    this.preview(this.props);
  }
 
  preview(props) {
    Client.resolvePreviewURL({linkResolver,defaultURL: '/'})
      .then(url => props.history.push(url))
      .catch(error => {console.error(error)
      this.setState({ error })
      })
   }
 
  render() {

    if(this.state.error) return <NotFound />

    return <p>Loading previews...</p>;
  }
}


// https://prismic.io/docs/reactjs/beyond-the-api/html-serializer
// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key })
}

// -- HTML Serializer
export const htmlSerializer = function(type, element, content, children, key, replaceDictionary) {
 
  if(replaceDictionary && content) {
    for (const key in replaceDictionary) {
      const val = replaceDictionary[key]
      content = content.replace(key, val)
    }
  }

  var props = {};

  if(type === Elements.heading2
    || type === Elements.heading3) {
      props.id = slugify(element.text, {lower: true, remove: /[*+~.,()'"!?:@]/g})
  }
 
  switch(type) {
      
    case Elements.heading1: // Heading 1
      return React.createElement('h1', propsWithUniqueKey(props, key), children);
      
    case Elements.heading2: // Heading 2
      return React.createElement('h2', propsWithUniqueKey(props, key), children);
      
    case Elements.heading3: // Heading 3
      return React.createElement('h3', propsWithUniqueKey(props, key), children);
      
    case Elements.heading4: // Heading 4
      return React.createElement('h4', propsWithUniqueKey(props, key), children);
      
    case Elements.heading5: // Heading 5
      return React.createElement('h5', propsWithUniqueKey(props, key), children);
      
    case Elements.heading6: // Heading 6
      return React.createElement('h6', propsWithUniqueKey(props, key), children);
      
    case Elements.paragraph: // Paragraph
      return React.createElement('p', propsWithUniqueKey(props, key), children);
      
    case Elements.preformatted: // Preformatted
      return React.createElement('pre', propsWithUniqueKey(props, key), children);
      
    case Elements.strong: // Strong
      return React.createElement('strong', propsWithUniqueKey(props, key), children);
      
    case Elements.em: // Emphasis
      return React.createElement('em', propsWithUniqueKey(props, key), children);
      
    case Elements.listItem: // Unordered List Item
      return React.createElement('li', propsWithUniqueKey(props, key), children);
      
    case Elements.oListItem: // Ordered List Item
      return React.createElement('li', propsWithUniqueKey(props, key), children);
      
    case Elements.list: // Unordered List
      return React.createElement('ul', propsWithUniqueKey(props, key), children);
      
    case Elements.oList: // Ordered List
      return React.createElement('ol', propsWithUniqueKey(props, key), children);
      
    case Elements.image: // Image
      const linkUrl = element.linkTo ? element.linkTo.url || linkResolver(element.linkTo) : null;
      const linkTarget = (element.linkTo && element.linkTo.target) ? { target: element.linkTo.target } : {};
      const linkRel = linkTarget.target ? { rel: 'noopener' } : {};
      const img = React.createElement('img', { src: element.url , alt: element.alt || '' });
      return React.createElement(
        'p',
        propsWithUniqueKey({ className: [element.label || '', 'block-img'].join(' ') }, key),
        linkUrl ? React.createElement('a', Object.assign({ href: linkUrl }, linkTarget, linkRel), img) : img
      );
      
    case Elements.embed: // Embed
      props = Object.assign({
        "data-oembed": element.oembed.embed_url,
        "data-oembed-type": element.oembed.type,
        "data-oembed-provider": element.oembed.provider_name,
      }, element.label ? {className: element.label} : {});
      const embedHtml = React.createElement('div', {dangerouslySetInnerHTML: {__html: element.oembed.html}});
      return React.createElement('div', propsWithUniqueKey(props, key), embedHtml);
      
    case Elements.hyperlink: // Image
      const targetAttr = element.data.target ? { target: element.data.target } : {};
      const relAttr = element.data.target ? { rel: 'noopener' } : {};
      props = Object.assign({ 
        href: element.data.url || linkResolver(element.data)
      }, targetAttr, relAttr);
      return React.createElement('a', propsWithUniqueKey(props, key), children);
      
    case Elements.label: // Label
      props = element.data ? Object.assign({}, { className: element.data.label }) : {};
      return React.createElement('span', propsWithUniqueKey(props, key), children);
      
    case Elements.span: // Span
      if (content) {
        return content.split("\n").reduce((acc, p) => {
          if (acc.length === 0) {
            return [p];
          } else {
            const brIndex = (acc.length + 1)/2 - 1;
            const br = React.createElement('br', propsWithUniqueKey({}, brIndex));
            return acc.concat([br, p]);
          }
        }, []);
      } else {
        return null;
      }
 
    default: // Always include a default that returns null
      return null;
  }
}

export const serializeWithRouterLink = (type, element, content, children, index) => {
  
  return (
    <Link key={element.data.id} to={linkResolver(element.data)}>
      {content}
    </Link>
  )
}