import React from 'react'
import Router from './Routes'
// import { Client, Prismic } from './components/prismic'
// import { matchPath } from 'react-router'

import './assets/stylesheets/main.scss'

import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import 'dayjs/locale/es'
import 'dayjs/locale/ja'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  // componentDidMount() {

  //   let lang
  //   let match = matchPath(window.location.pathname, {
  //     path: '/:lang([a-z]{2})',
  //     exact: true,
  //     strict: false
  //   })

  //   if(!match)
  //     match = matchPath(window.location.pathname, {
  //       path: '/:lang([a-z]{2})/:rest',
  //       exact: false,
  //       strict: false
  //     })

  //   lang = match && match.params.lang

  //   Client.getSingle('layout', { 'fetchLinks' : 'blog_category.title', lang })
  //     .then(layout => {

  //       if(!layout) {
  //         Client.getSingle('layout', { 'fetchLinks' : 'blog_category.title' })
  //           .then(layout => {
  //             this.setState({ layout, loaded : true })
  //           })
  //           .catch(error => this.setState({ error }))
  //       }
  //       else {
  //         this.setState({ layout, loaded : true })
  //       }
  //     })
  //     .catch(error => this.setState({ error }))

  //   // Client.query(
  //   //   Prismic.Predicates.at('document.type', 'layout'),
  //   //   { 'fetchLinks' : 'blog_category.title', 'lang' : '*' }
  //   // )
  //   // .then(response => {

  //   //   let layout = response && response.results && response.results.find(_ => _.lang === lang)
  //   //   if(!layout)
  //   //     layout = response && response.results && response.results.find(_ => _.lang === 'en-us')

  //   //   this.setState({ layout })
  //   // })
  //   // .catch(error => this.setState({ error }))
  // }

  // componentDidUpdate(prevProps) {
  //   if(this.state.page) window.prerenderReady = true

  //   const currentParams = this.props.computedMatch.params
  //   const prevParams = prevProps.computedMatch.params

  //   if (currentParams && prevParams && currentParams.lang !== prevParams.lang) {
  //     this.fetchData(currentParams.lang)
  //   }
  // }

  render() {
    // if(!this.state.layout) return ''
    // else return <Router layout={this.state.layout} />
    // if(!this.state.loaded) return ''
    // else return <Router layout={this.state.layout} />
    return <Router />
  }
}
